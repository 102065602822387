/* eslint-disable sort-keys */
import Joi from 'joi';

// eslint-disable-next-line import/order
import { NETWORKS, TOKENS } from '../../components/ProjectsWallet/Wallet.d';
import {
  MAX_INVESTMENT_PERCENTAGE_DEVELOPMENT_INCOME,
  MAX_INVESTMENT_PERCENTAGE_LOANS,
  PROJECT_TYPES,
} from './ProjectDetail.d';

const maxPercentageErrorMessage = {
  'number.max': 'The maximum percentage allowed is 100',
  'number.unsafe': 'The maximum percentage allowed is 100',
};

const maxInvestmentPercentageErrorMessage = {
  'number.max': `The maximum percentage allowed is ${MAX_INVESTMENT_PERCENTAGE_LOANS} for Loans, ${MAX_INVESTMENT_PERCENTAGE_DEVELOPMENT_INCOME} for Development/Income`,
  'number.unsafe': `The maximum percentage allowed is ${MAX_INVESTMENT_PERCENTAGE_LOANS} for Loans, ${MAX_INVESTMENT_PERCENTAGE_DEVELOPMENT_INCOME} for Development/Income`,
};

const unsafeNumberErrorMessage = {
  'number.unsafe': 'This amount exceeds the allowed number of characters',
};

const invalidLabel = (): string => {
  return 'Invalid {{#label}}';
};

const financialSchema = Joi.object({
  actualAnnualReturn: Joi.number()
    .max(1000)
    .optional()
    .allow('')
    .messages(maxPercentageErrorMessage),
  annualCash: Joi.number().max(100).optional().allow('').messages(maxPercentageErrorMessage),
  annualInvestment: Joi.number().max(100).optional().allow('').messages(maxPercentageErrorMessage),
  assetManagementFee: Joi.number()
    .max(100)
    .optional()
    .allow('')
    .messages(maxPercentageErrorMessage),
  financialReports: Joi.array().optional(),
  frequencyDistribution: Joi.string().optional().allow(''),
  investmentBreakpoint: Joi.number()
    .max(100)
    .optional()
    .allow('')
    .messages(maxPercentageErrorMessage),
  maximumInvestmentPercentage: Joi.number().when(Joi.ref('/projectType'), {
    is: PROJECT_TYPES.LOANS.toLowerCase(),
    then: Joi.number()
      .max(MAX_INVESTMENT_PERCENTAGE_LOANS)
      .optional()
      .allow('')
      .messages(maxInvestmentPercentageErrorMessage),
    otherwise: Joi.number()
      .max(MAX_INVESTMENT_PERCENTAGE_DEVELOPMENT_INCOME)
      .optional()
      .allow('')
      .messages(maxInvestmentPercentageErrorMessage),
  }),
  minimumInvestment: Joi.number().optional().allow('').messages(unsafeNumberErrorMessage),
  percentOverage: Joi.number().max(100).optional().allow('').messages(maxPercentageErrorMessage),
  placementFee: Joi.number().max(100).optional().allow('').messages(maxPercentageErrorMessage),
  totalReturnInvestment: Joi.number().max(999).optional().allow('').messages({
    'number.max': 'The maximum percentage allowed is 999',
    'number.unsafe': 'The maximum percentage allowed is 999',
  }),
  totalTargetedInvestment: Joi.number().optional().allow('').messages(unsafeNumberErrorMessage),
  upfrontFee: Joi.number().max(100).optional().allow('').messages(maxPercentageErrorMessage),
});

const legalSchema = Joi.object({
  document: Joi.string().optional().allow(''),
});

const bankAccountSchema = Joi.object({
  accountNumber: Joi.string()
    .min(6)
    .max(35)
    .allow('')
    .regex(/^[a-zA-Z0-9]*$/)
    .label('Bank Account Number')
    .messages({
      'string.pattern.base': 'Invalid {{#label}} (only numbers and letters)',
    }),
  bankAddress: Joi.string().allow('').label('Bank Address'),
  bankCity: Joi.string().allow('').label('Bank City'),
  bankCountry: Joi.string()
    .valid('US')
    .min(0)
    .max(2)
    .allow('')
    .regex(/^[a-zA-Z]*$/)
    .label('Bank Country')
    .messages({
      'string.pattern.base': 'Invalid {{#label}} (two-letter code)',
    }),
  bankName: Joi.string().allow('').label('Bank Name'),
  bankPostalCode: Joi.string().min(4).max(16).allow('').label('Zip').messages({
    'string.pattern.base': invalidLabel(),
  }),
  beneficiaryAddress: Joi.string().allow('').label('Beneficiary Address'),
  beneficiaryName: Joi.string().allow('').label('Beneficiary Name'),
  routingNumber: Joi.string()
    .length(9)
    .allow('')
    .regex(/^[0-9]*$/)
    .label('Routing Number')
    .messages({
      'string.pattern.base': 'Invalid {{#label}} for US. Only numbers are allowed',
    }),
  swiftCode: Joi.string()
    .min(8)
    .max(11)
    .allow('')
    .regex(/^[a-zA-Z0-9]*$/)
    .label('SWIFT / BIC code')
    .messages({
      'string.pattern.base': invalidLabel(),
    }),
});

const walletSchema = Joi.object({
  address: Joi.string()
    .max(42)
    .allow('')
    .regex(/^0x[0-9a-fA-F]{40}$/)
    .label('Address')
    .messages({
      'string.pattern.base': `${invalidLabel()} for Ethereum network`,
    }),
  currency: Joi.string().allow('', TOKENS.USDC, TOKENS.USDT).label('Currency').messages({
    'string.pattern.base': invalidLabel(),
  }),
  network: Joi.string().allow('', NETWORKS.ETH, NETWORKS.POLYGON).label('Network').messages({
    'string.pattern.base': invalidLabel(),
  }),
});

const beneficiaryWalletsSchema = Joi.object({
  ETH: walletSchema,
  POLYGON: walletSchema,
});

const updateProjectSchema = Joi.object({
  beneficiaryBank: bankAccountSchema,
  beneficiaryWallets: beneficiaryWalletsSchema,
  canBeDuplicated: Joi.boolean(),
  closingDate: Joi.date().allow('').greater('now'),
  financialInformation: financialSchema,
  investmentPeriod: Joi.string().optional().allow(''),
  isUs: Joi.boolean().optional(),
  legalInformation: legalSchema,
  projectId: Joi.string().optional(),
  projectTitle: Joi.string().required().messages({ 'string.empty': 'Project title is required' }),
  projectType: Joi.string().required().messages({
    'string.empty': 'Project type is required',
  }),
});

export { updateProjectSchema };
