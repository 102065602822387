import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useOutletContext, useParams } from 'react-router-dom';

import { getProjectLegalDocuments } from '../../../api/projects';
import { IOption } from '../../../components/Select';
import { isRequestError } from '../../../helpers/isRequestError';
import { useAbortRequest } from '../../../hooks/useAbortRequest';
import { IFormOutletContext } from '../ProjectDetail.d'; //eslint-disable-line
import { Legal } from './Legal';

const LegalPresenter: FC = () => {
  const { id } = useParams();
  const [documents, setDocuments] = useState<IOption[]>();
  const getProjectLegalDocumentsController = useAbortRequest();
  const { validStatus, setShowEditButton, legalRole, financialRole } = useOutletContext<IFormOutletContext>();
  const canEdit = validStatus && (legalRole || financialRole);
  const { watch } = useFormContext();

  const documentId = watch('legalInformation.document');
  const isUs = watch('isUs');

  useEffect(() => {
    const reqProjectLegalDocuments = async () => {
      const { data, status } = await getProjectLegalDocuments(
        getProjectLegalDocumentsController.signal
      );
      if (!isRequestError(status)) {
        setDocuments(data as IOption[]);
      }
    };
    reqProjectLegalDocuments();
  }, [getProjectLegalDocumentsController, id]);

  useEffect(() => {
    setShowEditButton(canEdit);
  }, [canEdit, setShowEditButton]);

  return <Legal documentId={documentId} documents={documents} canEdit={canEdit} isUs={isUs}/>;
};

export { LegalPresenter };
