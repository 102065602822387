import { TTransactionStatus } from '../../components/ProjectInvestors';
import { IOption } from '../../components/Select';
import { INVESTMENT_STATUSES } from '../../helpers/constants';
import { responseChecker } from '../../helpers/responseChecker';
import { IWallet } from '../../pages/InvestorDetail';
import { IProject, IProjectForm } from '../../pages/ProjectDetail';
// eslint-disable-next-line import/order
import { IInvestorDetail } from '../investors/investors.d';
import {
  ICreateProjectReturn,
  IProjectsInvestmentsResponse,
  IProjectsList,
  IProjectsResponse,
  IUpdateProjectStatus,
  TDocumentLegal,
} from './projects.d';

export const createProject = (data: ICreateProjectReturn): ICreateProjectReturn => {
  responseChecker<ICreateProjectReturn>(data, ['projectId', 'message']);
  const { projectId, message } = data;
  return { message, projectId };
};

export const getProjectDetail = (data: IProject): IProject => {
  const initProps = [
    'projectId',
    'projectTitle',
    'investmentPeriod',
    'status',
    'projectType',
    'metrics',
    'isUs'
  ];
  responseChecker<IProject>(data, initProps);
  const { projectId, projectTitle, investmentPeriod, status, projectType, metrics, category, isUs } =
    data;
  const objReturn: IProject = {
    category,
    investmentPeriod,
    isUs,
    metrics,
    projectId,
    projectTitle,
    projectType,
    status,
  };
  if (data.hasOwnProperty('canBeDuplicated')) {
    objReturn.canBeDuplicated = data.canBeDuplicated;
  }

  if (data.hasOwnProperty('closingDate')) {
    objReturn.closingDate = data.closingDate;
  }

  if (data.hasOwnProperty('legalInformation')) {
    objReturn.legalInformation = data.legalInformation;
  }

  if (data.hasOwnProperty('startDate')) {
    objReturn.startDate = data.startDate;
  }

  if (data.hasOwnProperty('financialInformation')) {
    objReturn.financialInformation = data.financialInformation;
  }

  if (data.hasOwnProperty('beneficiaryBank')) {
    objReturn.beneficiaryBank = data.beneficiaryBank;
  }

  if (data.hasOwnProperty('beneficiaryWallets')) {
    objReturn.beneficiaryWallets = data.beneficiaryWallets;
  }

  if (data.hasOwnProperty('hidden')) {
    objReturn.hidden = data.hidden;
  }
  return objReturn;
};

export const getProjects = (data: IProjectsResponse): IProjectsList => {
  const { projects, pages, page, count } = data;
  return {
    count,
    page,
    pages,
    projects: projects.map((project) => ({
      ...project,
      financial: { ...project.financialInformation },
    })),
  };
};

export const getProjectLegalDocuments = (arr: IOption[]): TDocumentLegal[] => {
  return arr.map((item: IOption) => {
    const { label, value } = item;
    return {
      label,
      value,
    };
  });
};

export const getInvestmentsDecorator = (
  data: IProjectsInvestmentsResponse,
  investors: IInvestorDetail[],
  whitelist: IWallet[]
): IProjectsInvestmentsResponse => {
  const { investments, pages, page, count, limit } = data;
  const decoratedInvestments = investments.map((investment, i) => ({
    ...investment,
    email: investors[i]?.email,
    fullName: investors[i]?.legalName,
    location: investors[i]?.location,
    status: investment?.status?.toLowerCase() as TTransactionStatus,
    transactions: investment?.transactions?.map((transaction) => {
      const currentStatus = transaction?.status?.toLowerCase();
      return {
        ...transaction,
        status: (currentStatus === INVESTMENT_STATUSES.REFUND_INPROCESS
          ? 'in_process'
          : currentStatus) as TTransactionStatus,
      };
    }),
    whitelisted: whitelist[i]?.whitelistStatus === 'Whitelisted' ? 'confirmed' : 'unconfirmed',
  }));

  return {
    count,
    investments: decoratedInvestments,
    limit,
    page,
    pages,
  };
};

export const cleanFieldsUpdateProject = (formData: IProjectForm | IUpdateProjectStatus ) => {
  delete (formData as IProjectForm).canBeDuplicated

  return formData;
}