const indexFields: { [propName: string]: string } = {
  annualCash: 'Annual cash on cash return/dividends (Financial)',
  annualInvestment: 'Net IRR on investment (Financial)',
  assetManagementFee: 'Asset management fee (Financial)',
  beneficiaryBank: 'Beneficiary Bank information (Financial)',
  beneficiaryWallets: 'Beneficiary Wallets (Financial)',
  closingDate: 'Closing date (Basic)',
  document: 'Documents (Legal)',
  frequencyDistribution: 'Frequency of distributions (Financial)',
  frequencyDistributionOther: 'Other (Frequency of distributions) (Financial)',
  investmentBreakpoint: 'Investment breakpoint (Financial)',
  investmentPeriod: 'Investment period (Basic)',
  maximumInvestmentPercentage: "Maximum investment percentage (Financial)",
  minimumInvestment: 'Minimum investment (Financial)',
  percentOverage: 'Percent overage allowed (Financial)',
  placementFee: 'Placement fee (Financial)',
  projectTitle: 'Project name (Basic)',
  projectType: 'Project type (Basic)',
  totalReturnInvestment: 'Total return on investment (Financial)',
  totalTargetedInvestment: 'Total targeted investment (Financial)',
  upfrontFee: 'Upfront fee (Financial)',
};

export const getLabelsByNames = (names: string[]): string[] => {
  return Object.keys(indexFields)
    .filter((item: string) => names.includes(item))
    .map((item: string) => indexFields[item]);
};
