// eslint-disable-next-line @typescript-eslint/no-empty-interface

interface IBankInfo {
  bankAddress: string;
  bankCity: string;
  bankCountry: string;
  bankName: string;
  bankPostalCode: string;
  beneficiaryName: string;
  beneficiaryAddress: string;
  accountNumber: string;
  routingNumber: string;
  swiftCode: string;
}

enum TOKENS {
  USDC = 'USDC',
  USDT = 'USDT',
}

enum NETWORKS {
  ETH = 'ETH',
  POLYGON = 'POLYGON',
}

const NETWORKS_OPTIONS: IOption = {
  ETH: { label: NETWORKS.ETH, value: NETWORKS.ETH },
  POLYGON: { label: NETWORKS.POLYGON, value: NETWORKS.POLYGON },
};

export { IBankInfo, NETWORKS, NETWORKS_OPTIONS, TOKENS };
