import { Box, Button, Divider, Grid, Link,Stack, SvgIcon, Typography } from '@mui/material';
import { IconArrowLeft, IconBriefcase } from '@tabler/icons';
import { FC, useContext, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { getReferrerAssociatedIds } from '../../api/brokers/brokers';
import { IReferrersResponse } from '../../api/brokers/brokers.d';
import { AddReferral } from '../../components/AddReferral/AddReferral';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { BasicCard, ReportWhitelist, Wallet } from '../../components/InvestorDetail';
import { TransactionsTable } from '../../components/TransactionsTable'; //eslint-disable-line
import { isRequestError } from '../../helpers/isRequestError';
import { useAbortRequest } from '../../hooks/useAbortRequest';
import { requireValidationContext } from '../../hooks/useRequireValidationOnLeave'; //eslint-disable-line
import { IInvestorDetailProps } from './InvestorDetail.d';

export const InvestorDetail: FC<IInvestorDetailProps> = ({
  basicData,
  investorIsEnabled,
  previewMode,
  switchPreviewMode,
  toggleBlockModal,
  tokensData,
  transactions,
  walletData
}) => {
  const requireValidation = useContext(requireValidationContext);
  const getReferrerAssociatedIdController = useAbortRequest();
  const goToInvestors = () => requireValidation?.showValidationOnNavigation('/investors');
  const isBroker = basicData?.groups?.some((group) => group === 'broker' || group === 'fa');
  const [associatedBrokerId, setAssociatedBrokerId] = useState('');
  const [referralCode, setReferralCode] = useState('');

  const reqReferrerAssociatedId = async (investorsIds: string[]) => {
    const { data, status } = await getReferrerAssociatedIds(
      investorsIds,
      getReferrerAssociatedIdController.signal
    );

    return !isRequestError(status) ? (data as IReferrersResponse[]) : '';
  };

  useEffect(() => {
    (async () => {
      if (basicData?.id) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const referrerAssociatedId = await reqReferrerAssociatedId([basicData.id]);
        setAssociatedBrokerId(referrerAssociatedId && referrerAssociatedId[0]?.brokerId);
        setReferralCode(referrerAssociatedId && referrerAssociatedId[0]?.referralCode);
      }
    })();
  }, [basicData]);

  return (
    <>
      <Box mb={4}>
        <Button
          color="secondary"
          onClick={goToInvestors}
          startIcon={<IconArrowLeft />}
          sx={{ fontSize: '12px' }}
        >
          Back to investors list
        </Button>
      </Box>
      <Breadcrumbs
        current="Investor details"
        linksList={[
          { label: 'Home', path: '/' },
          { label: 'Investors', path: '/investors' },
        ]}
      />
      <Grid columns={{ lg: 12, sm: 8, xs: 4 }} container sx={{ mt: 1, p: { xs: 0 } }} spacing={3}>
        <Grid item xs={4} sm={4} lg={8}>
          <Stack direction="row" spacing={1} alignItems="center">
            <SvgIcon sx={{ color: 'secondary.main' }}>
              <IconBriefcase />
            </SvgIcon>
            <Typography variant="h1">Investor details</Typography>
          </Stack>
        </Grid>
      </Grid>
      <Divider sx={{ my: 3 }} />

      <Grid
        columns={{ xs: 4, sm: 8, lg: 10 }} //eslint-disable-line
        spacing={4}
        position="relative"
        container
      >
        <Grid item xs={4} sm={8} lg={10}>
          <BasicCard
            investorIsEnabled={investorIsEnabled}
            toggleBlockModal={toggleBlockModal}
            basicData={basicData}
            previewMode={previewMode}
            switchPreviewMode={switchPreviewMode}
          />
        </Grid>
        <Grid item xs={4} sm={8} lg={3}>
          <ReportWhitelist basicData={basicData} walletData={walletData} />
        </Grid>
        <Grid item xs={4} sm={8} lg={7}>
          <Wallet walletData={walletData} tokensData={tokensData} />
        </Grid>
      </Grid>

      <Box mt={5} mb={3}>
        <Typography variant="h3" color="grey.800">
          Holdings
        </Typography>
      </Box>

      <TransactionsTable {...transactions} />

      {!isBroker && (
        <>
          <Box mt={5} mb={3}>
            <Typography variant="h3" color="grey.800">
              Connect with Broker
            </Typography>
          </Box>
          {associatedBrokerId ? (
            <Typography>
              Already connected to Broker{' '}
              <Link
                component={RouterLink}
                sx={{
                  '&:hover': { textDecoration: 'underline' },
                  cursor: 'pointer',
                  display: 'flex',
                }}
                to={'/brokers-and-financial-advisors/broker/' + associatedBrokerId}
              >
                {referralCode}
              </Link>
            </Typography>
          ) : (
            <AddReferral investorId={basicData?.id} />
          )}
        </>
      )}
    </>
  );
};
