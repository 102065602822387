import { Divider, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';

import { ENVS, getEnv, PROJECT_STATUSES } from '../../helpers/constants';
import {
  IFormOutletContext,
  IProjectOutletContext,
} from '../../pages/ProjectDetail/ProjectDetail.d';
import { Gray } from '../../theme/tokens/colors';
import { InputEndAdornment } from '../InputField/InputEndAdornment';
import { IOption } from '../Select';
// eslint-disable-next-line import/order
import { Select } from '../Select/Select';
import { NETWORKS, NETWORKS_OPTIONS } from './Wallet.d';

export const WalletForm: React.FC = () => {
  const { status } = useOutletContext<IProjectOutletContext>();
  const { financialRole, setShowEditButton } = useOutletContext<IFormOutletContext>();
  const {
    control,
    watch,
    register,
    formState: { errors },
  } = useFormContext();

  const canEdit =
    [PROJECT_STATUSES.DRAFT, PROJECT_STATUSES.ACTIVE, PROJECT_STATUSES.PAUSED].includes(
      status as PROJECT_STATUSES
    ) && financialRole;
  const isProd = getEnv() === ENVS.PROD;
  const currencyOptions: IOption[] = [{ label: 'USDC', value: 'USDC' }];
  const currencyOptionsPolygon: IOption[] = [{ label: 'USDT', value: 'USDT' }];

  useEffect(() => {
    setShowEditButton(canEdit);
  }, [canEdit, setShowEditButton]);

  return (
    <>
      <Divider sx={{ mb: 5, mt: 7 }} />
      <Grid item xs={12} container spacing={4}>
        <Grid item md={12} xs={12}>
          <Typography variant="h1" color={Gray[800]}>
            Wallet Information
          </Typography>
        </Grid>
        {Object.values(NETWORKS_OPTIONS as IOption).map((network: IOption) => {
          return (
            <>
              <Grid item md={6} xs={12}>
                <InputEndAdornment
                  label="Address"
                  adornment=""
                  inputProps={{
                    maxLength: 42,
                    ...register(`beneficiaryWallets.${network.value}.address`, { required: true }),
                  }}
                  error={!!errors?.beneficiaryWallets?.[network.value]?.address}
                  helperText={errors?.beneficiaryWallets?.[network.value]?.address?.message}
                  value={watch(`beneficiaryWallets.${network.value}.address`)}
                  disabled={!canEdit}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Select
                  fullWidth
                  label="Network"
                  options={[network]}
                  control={control}
                  name={`beneficiaryWallets.${network.value}.network`}
                  value={watch(`beneficiaryWallets.${network.value}.network`)}
                  helperText={errors?.beneficiaryWallets?.[network.value]?.network?.message}
                  error={!!errors?.beneficiaryWallets?.[network.value]?.network}
                  disabled={!canEdit}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Select
                  fullWidth
                  label="Currency"
                  options={!isProd && network.value === NETWORKS.POLYGON ? currencyOptionsPolygon: currencyOptions }
                  control={control}
                  name={`beneficiaryWallets.${network.value}.currency`}
                  value={watch(`beneficiaryWallets.${network.value}.currency`)}
                  helperText={errors?.beneficiaryWallets?.[network.value]?.currency?.message}
                  error={!!errors?.beneficiaryWallets?.[network.value]?.currency}
                  disabled={!canEdit}
                />
              </Grid>
            </>
          );
        })}
      </Grid>
    </>
  );
};
