
// eslint-disable-next-line simple-import-sort/imports
import { IProject } from '.';

// eslint-disable-next-line import/order
import {
  IProjectForm,
  ITabsDisplay,
  MAX_INVESTMENT_PERCENTAGE_DEVELOPMENT_INCOME,
  MAX_INVESTMENT_PERCENTAGE_LOANS,
  PROJECT_TYPES,
} from './ProjectDetail.d';

const formFieldsDecorator = (data: IProject): IProjectForm => {
  const {
    projectId,
    projectTitle,
    investmentPeriod,
    projectType,
    closingDate,
    financialInformation,
    beneficiaryBank,
    beneficiaryWallets,
    legalInformation,
    isUs,
    canBeDuplicated,
  } = data;
  return {
    beneficiaryBank,
    beneficiaryWallets,
    canBeDuplicated,
    closingDate,
    financialInformation: {
      annualCash: financialInformation?.annualCash,
      annualInvestment: financialInformation?.annualInvestment,
      assetManagementFee: financialInformation?.assetManagementFee,
      financialReports: financialInformation?.financialReports,
      frequencyDistribution: financialInformation?.frequencyDistribution,
      investmentBreakpoint: financialInformation?.investmentBreakpoint,
      maximumInvestmentPercentage: financialInformation?.maximumInvestmentPercentage,
      minimumInvestment: financialInformation?.minimumInvestment,
      percentOverage: financialInformation?.percentOverage,
      placementFee: financialInformation?.placementFee,
      totalReturnInvestment: financialInformation?.totalReturnInvestment,
      totalTargetedInvestment: financialInformation?.totalTargetedInvestment,
      upfrontFee: financialInformation?.upfrontFee,
    },
    investmentPeriod,
    isUs,
    legalInformation,
    projectId,
    projectTitle,
    projectType,
  };
};

const validateRole = (form: IProjectForm, isEditor: ITabsDisplay): IProjectForm => {
  const validForm = { ...form };
  !isEditor.basic && delete validForm.closingDate;
  !isEditor.basic && delete validForm.investmentPeriod;
  !isEditor.basic && delete validForm.projectTitle;
  !isEditor.basic && delete validForm.projectType;
  !isEditor.financial && delete validForm.financialInformation;
  !isEditor.financial && delete validForm.beneficiaryBank;
  !isEditor.financial && delete validForm.beneficiaryWallets;
  !isEditor.legal && delete validForm.legalInformation;
  return validForm;
};

const formatFormRequest = (form: IProjectForm, isEditor: ITabsDisplay): IProjectForm => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { projectId, ...formWithoutProjectId } = form;
  const formatedForm: IProjectForm = {
    ...formWithoutProjectId,
    financialInformation: {
      annualCash: form?.financialInformation?.annualCash || 0,
      annualInvestment: form?.financialInformation?.annualInvestment || 0,
      assetManagementFee: form?.financialInformation?.assetManagementFee || 0,
      frequencyDistribution: form?.financialInformation?.frequencyDistribution,
      investmentBreakpoint: form?.financialInformation?.investmentBreakpoint || 0,
      maximumInvestmentPercentage:
        form?.financialInformation?.maximumInvestmentPercentage ||
        (form.projectType === PROJECT_TYPES.LOANS.toLocaleLowerCase()
          ? MAX_INVESTMENT_PERCENTAGE_LOANS
          : MAX_INVESTMENT_PERCENTAGE_DEVELOPMENT_INCOME),
      minimumInvestment: form?.financialInformation?.minimumInvestment || 0,
      percentOverage: form?.financialInformation?.percentOverage || 0,
      placementFee: form?.financialInformation?.placementFee || 0,
      totalReturnInvestment: form?.financialInformation?.totalReturnInvestment || 0,
      totalTargetedInvestment: form?.financialInformation?.totalTargetedInvestment || 0,
      upfrontFee: form?.financialInformation?.upfrontFee || 0,
    },
  };
  return validateRole(formatedForm, isEditor);
};

export { formatFormRequest, formFieldsDecorator, validateRole };
